import { Content, Headline, Main } from 'components/page'
import { FormattedMessage } from 'react-intl'

export const Prismic404 = () => {
    return (
        <div>
            <Content>
                <Main>
                    <Headline>
                        <FormattedMessage
                            id="prismic.404.title"
                            defaultMessage="Sorry, we couldn't load the data for this page. Please try again later."
                        />
                    </Headline>
                </Main>
            </Content>
        </div>
    )
}
