import * as React from 'react'

import Head from 'components/Head'
import { Content, Header, Headline, Main, Banner, Aside } from 'components/page'
import { StructuredText, SliceZone } from 'prismic/components/base'
import Sidebar from 'components/sidebar'
import { Prismic404 } from 'prismic/layouts/Prismic404'

export function Static(props) {
    const { data = null } = props.document || {}

    if (!data) {
        return <Prismic404 />
    }

    const { headline, content, title } = data

    return (
        <React.Fragment>
            <PageBanner {...data} />
            <Header title={title} />
            <Content>
                <Main>
                    <Head title={title} description={title} />
                    {headline && <Headline>{headline}</Headline>}
                    {Array.isArray(content) && <SliceZone value={content} />}
                </Main>
                <PageAside {...data} />
            </Content>
        </React.Fragment>
    )
}

// Util components
// TODO Potentioally rename that component and accept only a document
export function GenericContent({ payload }) {
    return <StructuredText value={payload?.data?.body} />
}

function PageAside({ sharing, following, contacting, sidebar = [], contact }) {
    sharing = sharing === 'Yes'
    following = following === 'Yes'
    contacting = contacting === 'Yes'

    if (sharing || following || contacting || sidebar.length) {
        contact = contacting ? (typeof contact === 'string' ? contact.replace(/^mailto:/, '') : true) : false

        return (
            <Aside>
                <Sidebar share={sharing} follow={following} contact={contact}>
                    <SliceZone value={sidebar} />
                </Sidebar>
            </Aside>
        )
    } else {
        return null
    }
}

function PageBanner({ banner }) {
    return banner?.url ? <Banner {...banner} /> : null
}
